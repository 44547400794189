.song img, .artist img {
	border:none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
  margin-top:20px;
  border-radius:0;
  margin-bottom:2px;
}

.song p, .artist p {
  margin-left: auto;
  margin-right: auto;
  color: #ccc; 
  text-align: center;
}

.song a, .artist a {
  color: #ccc; 
  text-decoration: none; 
}

.song, .artist {
  width: calc(50% - 20px);
  margin-right: 9px;
  margin-left: 9px;
  margin-bottom: 35px;
  position: relative;
  z-index: 1; 
  overflow: hidden; 
background-color:rgba(100,100,100,0.5);
border-radius: 3px;
border:1px solid rgba(100,100,100,0.5);
}
.song:hover .search-options, 
.artist:hover .search-options {
  display: flex;
}

@media (min-width: 584px) {
  .song, .artist {
    width: calc(33.33% - 20px);
  }
}

@media (min-width: 772px) {
  .song, .artist {
    width: calc(25% - 20px);
  }
}

@media (min-width: 960px) {
  .song, .artist {
    width: calc(20% - 20px);
  }
}

body {
  background-color: black;
  margin: 0px;
}

.search-options button {
  background-color: transparent;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  opacity: 0.8;
  transform: scale(0.3);
  animation: fadeIn 0.4s ease forwards; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 0.8;
    transform: scale(1); 
  }
}
.search-options button img {
  width: 30px;
  height: auto;
}
.search-options {
  position: absolute;
  top: 120px;
  left: 50%; 
  transform: translateX(-50%); 
  display: none;
  justify-content: center;
}

.title {
  margin-top: 75px;
}


.box {
  color:#ccc;
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 35px;
  position: relative;
  z-index: 1; 
  overflow: hidden; 
background-color:rgba(100,100,100,0.5);
border-radius: 6px;
padding:20px;
  text-align: center;
    text-decoration: none; 
}
.box img {
	  border-radius:50%;
	    border:50px solid black;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    max-width: 180px;
    max-height: 180px;
}


.box:hover img {
 animation: rotateImage 15s linear infinite; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    max-width: 180px;
    max-height: 180px;

}

.container1 {
  display: flex;
  flex-wrap: wrap;
margin-top:100px;
}
@media (min-width: 720px) {
  .box {
    width: calc(50% - 60px);
  }
}

@media (min-width: 1200px) {
  .box {
    width: calc(33% - 60px);
  }
}


.rotate {
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes rotateImage {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg);
  }
}
.song img.selected {
  border-radius: 50%;
  border: 30px solid black;
  max-width: calc(180px - 75px);
  max-height: calc(180px - 75px);
  transition: border-radius 0.5s ease, border 0.5s ease, width 0.5s ease, height 0.5s ease, transform 0.5s ease;
}

.lyrics-container {
max-height:150px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  width: 100%; 
}

.lyrics-container p {
  text-align: center;
  padding: 2px;
  margin: 0px; 
}

.s-black {
  color: #e69b8c;
  font-size:12px;
}
.bold-black {
  color: #000000;
  font-weight: bold;
  font-size:15px;
}
.react-audio-player {
width: 100%; 
margin-bottom:0px;

}

.react-audio-player::-webkit-media-controls-panel {
  background-color: #e69b8c;

}

.loader {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:#c45039;
  z-index: 999;
}

