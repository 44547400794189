/* Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px; 
  background-color: rgba(100, 100, 100, 0.5);
  color: #ccc;
  z-index: 1000;
  backdrop-filter: blur(5px);
}
.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
   color: rgba(255, 255, 255, 0.8); 
}
.navbar-logo img {
  height: 40px;
  width: auto;
  margin-right: 10px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
  }
}
.page-name {
  font-size: 18px; 
  margin-left: 10px; 
   color: rgba(255, 255, 255, 0.8);
}
.navbar-toggle {
  display: none;
  cursor: pointer;
  margin-right:5px;
}
.bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 6px 0;
}
.navbar-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  color: rgba(219, 215, 215, 0.8);

}
.navbar-links li {
  margin-right: 20px;
}
.navbar-links a {
  text-decoration: none;
  color: #dbd7d7;

}
@media (max-width: 1100px) {
  .navbar-links {
	overflow-y: auto;
    max-height: calc(100vh - 100px);
    display: none;
    flex-direction: column;
    width: auto;
	margin-right:15px;
	margin-left:15px;
    position: absolute;
    top: 54px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8); 
	    transition: all 0.3s ease;
  }
  .navbar-links.active {
    display: flex;
  animation: slideDown 0.3s ease; 
  }
  .navbar-links li {
    margin-right: 0;
    text-align: left;
    padding: 10px;
  }
  .navbar-toggle {
    display: block;
  }
}
h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
}




@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0); 
  }
}